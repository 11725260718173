.edu-image {
    width: 100%;
    height: 250px;
    box-shadow: 0px 10px 13px -7px #000000;
}

.education {
    background-color: #f2f2f2;
}

@media(max-width: 768px) {
   .edu-image {
       margin-bottom: 50px;
   }
}