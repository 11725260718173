/* .intro {
    background: url('../../images/laptop.jpg') center center no-repeat;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    background-size: cover;
} */

.intro {
  position: relative;
  background: url('../../images/laptop.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.dark-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.my-name {
  padding-top: 50px;
}

.profile-img {
  width: 300px;
  height: 200px;
  border: 2px dashed rgb(137, 72, 199);
}

.work-btn {
  padding-left: 60px;
  padding-right: 60px;
  border-radius: 0;
}

#intro-content {
  padding-bottom: 140px;
}

.keep {
  padding-top: 56px;
}

.button_2 {
  background-color: rgba(0, 0, 0, 0.1);
  display: inline-block;
  padding: 15px 30px;
  margin: 10px;
  font-size: 20px;
  font-family: 'Gill Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
  color: rgb(95,39,205);
  cursor: pointer;
  border: 2px solid rgb(95,39,205);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.button_2:hover {
  background-color: rgb(95,39,205);
  color: #fff;
  text-decoration: none;
}

.skill-ticker {
  width: 60%;
  margin: auto;
}



@media(max-width: 768px) {

  .my-name {
    font-size: 30px;
  }

  .my-job {
    font-size: 50px;
  }

  .skill-ticker {
    width: 80%;
  }
}