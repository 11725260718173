.activites {
    background: url('../../images/Zig-Zag.svg');
    background-size: 20% 20%;
}

.activity-slide {
    width: 70%;
}

.cap-background {
    background-color: rgba(173, 173, 173, 0.781);
    width: 25%;
    margin: auto;
}

.activity-desc {
    width: 70%;
    margin: auto;
    margin-top: 25px;
}

.activity-desc p {
    font-size: 18px;
}

@media(max-width: 992px) {
    .activites {
        background-size: cover;
    }
}